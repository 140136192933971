import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import ErrorPage, { ErrorPageData } from 'templates/ErrorPage';
import { PageTitle } from 'helpers/eventTracking';
import { usePageTracking } from 'helpers/pageTracking';

export const query = graphql`
  query {
    csErrorPage(error_id: { eq: "pet-500-min-max-premium-error" }) {
      ...ErrorPage
    }
  }
`;

/* istanbul ignore next */
const MinMaxPremiumErrorPage: React.FC = () => {
  const data = useStaticQuery<ErrorPageData>(query);
  usePageTracking(data.csErrorPage.meta_title);

  return <ErrorPage data={data} pageTitle={PageTitle.BusinessError} />;
};

export default MinMaxPremiumErrorPage;
